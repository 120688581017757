import { useEffect, useState } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { scrollToElement } from "../../utils/scrollToElement";
import { Nav } from "../Nav";
import { Button } from "../UI";
import { Link } from "react-router-dom";
import configs from "../../config.json";

import cn from "classnames";
import styles from "./Header.module.scss";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const { BRAND_NAME } = configs;

  const handleNavOpen = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    if (isNavOpen && !isMobile) {
      setIsNavOpen(false);
    }
  }, [isNavOpen, isMobile]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const body = document.body;
      body.classList.toggle("disabled-scroll", isNavOpen);
    }
  }, [isNavOpen]);

  return (
    <header className={styles["header"]}>
      <div className={cn("wrapper", styles["header__wrapper"])}>
        <Link to="/" className={styles["header__logo"]}>
          <img
            src={"/images/logo.svg"}
            width="206"
            height="29"
            alt={`${BRAND_NAME} logo`}
          />
        </Link>

        <Nav isNavOpen={isNavOpen} onNavClose={() => setIsNavOpen(false)} />

        <Button
          className={styles["header__button"]}
          to="/#donate"
          onClick={(e) => scrollToElement("donate")(e)}
          rel="nofollow"
          theme="small"
        >
          Become a donor
        </Button>

        <button
          className={cn(
            "hamburger",
            "hamburger--spin",
            styles["header__hamburger"],
            { "is-active": isNavOpen },
          )}
          type="button"
          aria-expanded={isNavOpen}
          aria-label={isNavOpen ? "closeTheMenu" : "openTheMenu"}
          onClick={handleNavOpen}
        >
          <span className={"hamburger-box"}>
            <span className={"hamburger-inner"}></span>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
