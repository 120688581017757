import cn from "classnames";
import styles from "./FaqSection.module.scss";
import { CollapseExpand } from "../UI/CollapseExpand";
import { useRef, useState } from "react";
import { IconArrowSmall } from "../UI";
import { faqConfig } from "./config";

const FaqSection = () => {
  return (
    <section className={styles["faq"]} id={"faq"}>
      <div className={cn("wrapper", styles["faq__wrapper"])}>
        <div className={styles["faq__additional-wrapper"]}>
          <h2 className={cn(styles["faq__title"], "main-title")}>FAQ</h2>
          <p className={styles["faq__description"]}>
            Find the answers to your questions regarding donations for our
            programming education platform.
          </p>

          <div className={styles["faq__items"]}>
            {faqConfig?.map(({ question, answer }) => (
              <FaqItem key={question} question={question} answer={answer} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const FaqItem = ({ question, answer }) => {
  const bodyBlock = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cn(styles["faq__item"], { [styles["active"]]: isOpen })}>
      <div className={styles["faq__item-top"]} onClick={toggleOpen}>
        <p className={styles["faq__item-title"]}>{question}</p>
        <IconArrowSmall className={styles["faq__item-icon"]} />
      </div>
      <CollapseExpand isOpen={isOpen}>
        <div className={styles["faq__item-bottom"]} ref={bodyBlock}>
          <p
            className={styles["faq__item-bottom-desk"]}
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      </CollapseExpand>
    </div>
  );
};

export default FaqSection;
