import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Buttons.module.scss";

const DefaultBtn = ({ children, theme = "main", className, ...props }) => (
  <button
    className={cn(styles.button, styles[`button--${theme}`], className)}
    {...props}
  >
    <span>{children}</span>
  </button>
);

const InternalLink = ({ children, theme = "main", className, ...props }) => (
  <Link
    className={cn(styles.button, styles[`button--${theme}`], className)}
    {...props}
  >
    <span>{children}</span>
  </Link>
);

const ExternalLink = ({ children, theme = "main", className, ...props }) => (
  <a
    className={cn(styles.button, styles[`button--${theme}`], className)}
    {...props}
  >
    <span>{children}</span>
  </a>
);

export const Button = (props) => {
  const { target, href, to, external } = props;

  return to && !target && !external ? (
    <InternalLink {...props} />
  ) : href && (target || external) ? (
    <ExternalLink {...props} />
  ) : (
    <DefaultBtn {...props} />
  );
};
