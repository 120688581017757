import { Button, IconPayPal } from "../UI";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { donateConfig } from "./config";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import cn from "classnames";
import styles from "./DonateSection.module.scss";

const DonateSection = () => {
  const swiperRef = useRef();
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const [swiperMobile, setSwiperMobile] = useState(false);

  useEffect(() => {
    setSwiperMobile(!isDesktop);
  }, [isDesktop]);

  const swiperParams = {
    slidesPerView: "auto",
    centeredSlides: true,
    initialSlide: 0,
    spaceBetween: 24,
    slideActiveClass: "is-active",
    modules: [Scrollbar],
    scrollbar: {
      el: ".js-donate-scrollbar",
      draggable: true,
      dragSize: "auto",
      dragClass: "donate__scrollbar-drag",
    },
    breakpoints: {
      425: {
        centeredSlides: false,
      },
    },
  };

  return (
    <section className={cn(styles["donate"], "donate")} id={"donate"}>
      <div className={cn("wrapper", styles["donate__wrapper"])}>
        <h2 className={cn(styles["donate__title"], "main-title")}>
          Help Us Shape the Future of Coding Education
        </h2>
        <div className={styles["donate__description"]}>
          <p>
            If you share our vision of a future where everyone can learn coding,
            let's make it happen together. Your support will play a key role in
            achieving our two major goals.
          </p>
          <p>
            Once we raise $50k, we'll be able to launch our website. And when we
            reach $200k, we’ll take it to the next level by developing a
            cutting-edge mobile application.
          </p>
        </div>
        <div className={styles["donate__items"]}>
          {swiperMobile ? (
            <>
              <div
                className={cn(
                  styles["donate__scrollbar"],
                  "js-donate-scrollbar",
                )}
              ></div>
              <Swiper
                {...swiperParams}
                ref={swiperRef}
                className={styles["donate__slider"]}
              >
                <div
                  className={cn(
                    "swiper-wrapper",
                    styles["donate__slider-wrapper"],
                  )}
                >
                  {donateConfig.map((el, index) => (
                    <SwiperSlide
                      key={`donate-${index}`}
                      className={styles["donate__slider-item"]}
                    >
                      <DonateItem
                        className={styles["donate__item"]}
                        plan={el}
                        index={index}
                        key={`donate-${index}`}
                      />
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </>
          ) : (
            <>
              {donateConfig?.map((el, index) => (
                <DonateItem plan={el} index={index} key={`donate-${index}`} />
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

const DonateItem = ({ plan, index }) => {
  const { price, button, items: features } = plan;

  return (
    <div className={styles["donate__item"]}>
      <div className={styles["donate__features"]}>
        {features?.map(({ title, text }, i) => (
          <div
            key={`donate-${index}-item-${i}`}
            className={styles["donate__feature"]}
          >
            <span className={styles["donate__feature-title"]}>{title}</span>
            <p
              className={styles["donate__feature-text"]}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </div>
        ))}
      </div>
      <span
        className={styles["donate__price"]}
        dangerouslySetInnerHTML={{ __html: price }}
      />
      <Button
        className={styles["donate__button"]}
        href={button.link}
        rel="nofollow"
        theme={"pay"}
        external="true"
      >
        <IconPayPal />
        {button.text}
      </Button>
    </div>
  );
};

export default DonateSection;
