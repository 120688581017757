import React from "react";
import { Helmet } from "react-helmet-async";
import HeroSection from "../components/HeroSection";
import OurVisionSection from "../components/OurVisionSection";
import AboutSection from "../components/AboutSection";
import ContactUsSection from "../components/ContactUsSection";
import {
  TickerChooseSection,
  TickerLogosSection,
} from "../components/TickerSection";
import FaqSection from "../components/FaqSection";
import DonateSection from "../components/DonateSection";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>CodeLearnFund: Support the Dreams of Future Coders / CodeLearnFund: Inspire Tomorrow's Tech Leaders</title>
        <meta
          name="description"
          content="At CodeLearnFund, we aim to make coding affordable and fun for all. Help us build an accessible, inclusive learning platform for aspiring coders."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeroSection />
      <OurVisionSection />
      <AboutSection />
      <TickerChooseSection />
      <DonateSection />
      <FaqSection />
      <TickerLogosSection />
      <ContactUsSection />
    </>
  );
};

export default Home;
