export const IconMspy = (props) => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
      <g clipPath="url(#clip0_3_259)">
        <rect
          x="60"
          y="60"
          width="60"
          height="60"
          transform="rotate(180 60 60)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1559 42.4897C16.8621 42.733 17.5094 42.8546 18.1568 42.8546C20.393 42.8546 22.4527 41.3949 23.0412 39.327L24.3359 33.61C24.4536 33.0626 25.1009 32.7585 25.6894 32.9409C25.9837 33.0626 26.2191 33.1842 26.3956 33.3667C27.7491 34.5831 29.8677 34.948 31.7509 34.2182C32.3982 33.9749 33.0455 33.4883 33.5163 33.0018L34.7522 31.7245C35.5172 30.8731 36.6942 30.4473 37.8712 30.569C38.4596 30.6298 39.0481 30.569 39.6366 30.4473C42.226 29.7783 43.6972 27.2239 43.1087 24.7911L42.226 21.0202C41.6963 19.0132 39.8132 17.4927 37.5181 17.4319C35.988 17.371 34.5168 18.0401 33.5163 19.074L30.927 21.7501C30.2796 22.4191 29.1027 22.4191 28.4553 21.7501L25.7483 18.9524C24.2182 17.371 21.8054 16.9453 19.7457 17.9184C18.3922 18.5266 17.5094 19.743 17.1563 21.1419L13.3311 37.0767C12.8015 39.2662 13.9196 41.6382 16.1559 42.4897ZM41.7101 42.8549C44.5217 42.8549 46.801 40.5756 46.801 37.764C46.801 34.9524 44.5217 32.6731 41.7101 32.6731C38.8984 32.6731 36.6191 34.9524 36.6191 37.764C36.6191 40.5756 38.8984 42.8549 41.7101 42.8549Z"
          fill="#242424"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_259">
          <rect width="60" height="60" rx="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconEyezy = (props) => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
      <circle
        cx="30"
        cy="30"
        r="29"
        fill="#242424"
        stroke="#242424"
        strokeWidth="2"
      />
      <g clipPath="url(#clip0_3_264)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 60C46.5685 60 60 46.5686 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5686 13.4315 60 30 60ZM30.0003 52.0001C17.8501 52.0001 8.00033 42.1504 8.00033 30.0001C8.00033 17.8498 17.8501 8.00009 30.0003 8.00009C42.1506 8.00009 52.0003 17.8498 52.0003 30.0001C52.0003 42.1504 42.1506 52.0001 30.0003 52.0001Z"
          fill="white"
        />
        <path
          d="M29.6667 41C36.8464 41 42.6667 35.1797 42.6667 28C42.6667 22.5979 39.3716 17.9653 34.6816 16.0026C34.7878 16.0008 34.8941 16 35.0007 16C45.4941 16 54.0006 24.5066 54.0006 35C54.0006 45.4934 45.4941 54 35.0007 54C24.5072 54 16.0007 45.4934 16.0007 35C16.0007 33.137 16.2688 31.3367 16.7685 29.6354C17.5728 36.0431 23.0409 41 29.6667 41Z"
          fill="white"
        />
        <path
          d="M26.6667 30C26.6667 32.7614 24.4281 35 21.6667 35C18.9052 35 16.6667 32.7614 16.6667 30C16.6667 27.2386 18.9052 25 21.6667 25C24.4281 25 26.6667 27.2386 26.6667 30Z"
          fill="white"
        />
        <circle cx="30" cy="30" r="29.5" stroke="#242424" />
      </g>
      <defs>
        <clipPath id="clip0_3_264">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconLasta = (props) => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
      <rect y="6.10352e-05" width="60" height="60" rx="30" fill="white" />
      <path
        d="M36.1569 21.4222H39.2301V22.9081L43.8531 27.5495C46.1441 29.8497 46.3744 33.5012 44.3907 36.0731C41.9879 39.1883 37.3911 39.4371 34.6686 36.5993L31.6941 33.4987L33.9075 31.3582L36.8821 34.4588C38.3043 35.9413 40.7056 35.8113 41.9608 34.1839C42.9971 32.8404 42.8768 30.9329 41.68 29.7313L37.7 25.7354L32.8071 30.6479L32.8019 30.6427L27.3776 36.0887C24.3771 39.1011 19.5125 39.1011 16.512 36.0887C13.5116 33.0762 13.5116 28.1921 16.512 25.1797C19.5125 22.1673 24.3771 22.1673 27.3776 25.1797L29.5525 27.3633L27.3794 29.5451L25.2045 27.3615C23.4042 25.5541 20.4854 25.5541 18.6852 27.3615C16.8849 29.169 16.8849 32.0994 18.6852 33.9069C20.4854 35.7143 23.4042 35.7143 25.2045 33.9069L25.5733 33.5366L25.5751 33.5384L30.639 28.4542L30.6424 28.4576L36.1569 22.9211V21.4222Z"
        fill="#242424"
      />
      <path
        d="M30.1986 38.3489L34.5447 38.3487L30.8522 34.6163L28.6754 36.8018L30.1986 38.3489Z"
        fill="#242424"
      />
    </svg>
  );
};

export const IconArrowSmall = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M17 14L12 9L7 14"
        stroke="#2B313B"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconPayPal = (props) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M5.59056 19.3216L5.93716 17.12L5.16508 17.1021H1.47832L4.04043 0.856567C4.04838 0.807525 4.07423 0.761796 4.11201 0.729323C4.14978 0.696849 4.19816 0.678955 4.24853 0.678955H10.4649C12.5287 0.678955 13.9529 1.1084 14.6965 1.95604C15.0451 2.35368 15.2671 2.76921 15.3744 3.22649C15.4871 3.70631 15.4891 4.27957 15.3791 4.97875L15.3711 5.02978V5.47779L15.7197 5.67528C16.0133 5.83103 16.2466 6.0093 16.4255 6.21342C16.7238 6.5534 16.9166 6.9855 16.9981 7.49779C17.0823 8.02466 17.0545 8.65161 16.9166 9.36139C16.7576 10.1779 16.5004 10.889 16.1531 11.4709C15.8337 12.007 15.4268 12.4517 14.9437 12.7963C14.4824 13.1237 13.9343 13.3722 13.3147 13.5313C12.7142 13.6877 12.0296 13.7666 11.2788 13.7666H10.795C10.449 13.7666 10.113 13.8912 9.84926 14.1145C9.58483 14.3425 9.40987 14.654 9.35619 14.9946L9.31974 15.1928L8.70738 19.0731L8.67954 19.2155C8.67225 19.2606 8.65966 19.2831 8.6411 19.2984C8.62454 19.3123 8.60068 19.3216 8.57748 19.3216H5.59056Z"
        fill="#253B80"
      />
      <path
        d="M16.0492 5.08179C16.0306 5.20042 16.0094 5.3217 15.9856 5.44629C15.1658 9.65529 12.3611 11.1093 8.77904 11.1093H6.9552C6.51714 11.1093 6.148 11.4274 6.07974 11.8595L5.14595 17.7817L4.88152 19.4604C4.83712 19.744 5.05582 19.9999 5.34212 19.9999H8.5769C8.95996 19.9999 9.28536 19.7215 9.34567 19.3438L9.37748 19.1794L9.98653 15.3144L10.0256 15.1023C10.0853 14.7232 10.4113 14.4448 10.7944 14.4448H11.2782C14.4122 14.4448 16.8657 13.1724 17.5827 9.49027C17.8823 7.95207 17.7272 6.6677 16.9346 5.7644C16.6947 5.49202 16.3971 5.26603 16.0492 5.08179Z"
        fill="#179BD7"
      />
      <path
        d="M15.1916 4.73957C15.0663 4.70311 14.9371 4.66998 14.8045 4.64016C14.6713 4.611 14.5348 4.58515 14.3943 4.56262C13.9025 4.48309 13.3637 4.44531 12.7865 4.44531H7.91412C7.79416 4.44531 7.68017 4.47248 7.57811 4.52153C7.35345 4.62955 7.18644 4.84229 7.14601 5.10274L6.1095 11.6678L6.07968 11.8593C6.14794 11.4272 6.51708 11.1091 6.95515 11.1091H8.77898C12.361 11.1091 15.1657 9.65438 15.9855 5.44604C16.01 5.32144 16.0306 5.20016 16.0491 5.08153C15.8417 4.97152 15.617 4.87741 15.3751 4.79722C15.3155 4.77734 15.2539 4.75812 15.1916 4.73957Z"
        fill="#222D65"
      />
      <path
        d="M7.14667 5.10261C7.1871 4.84216 7.3541 4.62942 7.57877 4.52206C7.68149 4.47301 7.79482 4.44584 7.91477 4.44584H12.7872C13.3644 4.44584 13.9032 4.48362 14.3949 4.56315C14.5354 4.58568 14.672 4.61153 14.8052 4.64069C14.9377 4.67051 15.067 4.70365 15.1922 4.7401C15.2545 4.75865 15.3161 4.77787 15.3764 4.79709C15.6183 4.87728 15.843 4.97205 16.0504 5.0814C16.2943 3.52597 16.0485 2.46693 15.2075 1.50796C14.2803 0.452227 12.6069 0.000244141 10.4656 0.000244141H4.24922C3.81181 0.000244141 3.4387 0.318355 3.3711 0.751118L0.781812 17.1636C0.730782 17.4884 0.981294 17.7813 1.30868 17.7813H5.14655L6.11016 11.6676L7.14667 5.10261Z"
        fill="#253B80"
      />
    </svg>
  );
};

export const IconArrowRight = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M4.95312 16.5H28.0531"
        stroke="black"
        strokeWidth="4.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7969 8.25L28.0469 16.5L19.7969 24.75"
        stroke="black"
        strokeWidth="4.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
