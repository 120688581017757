import { Image } from "../UI";

import cn from "classnames";
import styles from "./OurVisionSection.module.scss";

const OurVisionSection = () => {
  return (
    <section className={styles["our-version"]}>
      <div className={cn("wrapper", styles["our-version__wrapper"])}>
        <div id={'our-vision'} className={cn(styles["our-version__additional-wrapper"], styles["reverse"])}>
          <div className={styles["our-version__main-container"]}>
            <h2 className={cn(styles["our-version__title"], "main-title")}>Our Vision</h2>
            <div className={styles["our-version__description"]}>
              <p>At CodeLearnFund, we see a world where coding is a universal language. Our vision is to foster a digital community where everyone, regardless of their socio-economic status, has the opportunity to learn, grow, and thrive through coding.</p>
              <p>Our passionate team of teachers and developers is committed to revolutionizing the way people learn to code. By raising funds, we aim to develop an educational platform and mobile app that will make programming education affordable, accessible, and fun for everyone.</p>
            </div>
          </div>
          <Image
            className={styles["our-version__image"]}
            src={"/images/home/our-vision.png"}
            width="495"
            height="500"
            alt="Our Vision"
          />
        </div>

        <div id={'our-story'} className={styles["our-version__additional-wrapper"]}>
          <div className={styles["our-version__main-container"]}>
            <h2 className={cn(styles["our-version__title"], "main-title")}>Our Story</h2>
            <div className={styles["our-version__description"]}>
              <p>In a world where coding has become an essential skill, CodeLearnFund originated from the realization that not everyone can afford quality education.</p>
              <p>The founders of CodeLearnFund seek to break down financial barriers, ensuring that anyone with a passion for coding can get the knowledge and skills to become a professional in the field.</p>
              <p>Driven by the understanding that learning knows no age boundaries, the initiative aims to cater to both children and adults, providing a diverse and inclusive learning environment.</p>
            </div>
          </div>
          <Image
            className={styles["our-version__image"]}
            src={"/images/home/our-story.png"}
            width="495"
            height="500"
            alt="Our Story"
          />
        </div>
      </div>
    </section>
  );
};

export default OurVisionSection;
