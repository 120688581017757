const navConfig = [
  {
    href: '/',
    text: 'Home',
    moveToId: 'top',
  },
  {
    href: '/#our-vision',
    text: 'Our Vision',
    moveToId: 'our-vision',
  },
  {
    href: '/#our-story',
    text: 'Our Story',
    moveToId: 'our-story',
  },
  {
    href: '/#donate',
    text: 'Help Us',
    moveToId: 'donate',
  },
  {
    href: '/#faq',
    text: 'FAQ',
    moveToId: 'faq',
  }
];

export { navConfig };
