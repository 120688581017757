import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { scrollToElement, scrollToTop } from "../../utils/scrollToElement";
import { Button } from "../UI";
import { navConfig } from "./config.js";

import cn from "classnames";
import styles from "./Nav.module.scss";

const Nav = ({ isNavOpen, onNavClose, isHideDesktop }) => {
  const [activeItem, setActiveItem] = useState("top");
  const { pathname } = useLocation();

  const handleScroll = (id) => (event) => {
    isNavOpen && onNavClose();
    id === "top" && pathname === "/" ? scrollToTop(event) : scrollToElement(id)(event);
    setActiveItem(id);
  };

  return (
    <nav
      className={cn(
        styles["nav"],
        { [styles["is-opened"]]: isNavOpen },
        { [styles["is-hide-desktop"]]: isHideDesktop },
      )}
    >
      <ul className={styles["nav-items"]}>
        {navConfig.map(({ href, text, moveToId }) => {
          return (
            <li key={href || text} className={styles["nav-item"]}>
              <Link
                to={href}
                onClick={handleScroll(moveToId)}
                className={cn(styles["nav-link"], {
                  [styles["active"]]: activeItem === moveToId && pathname === "/",
                })}
                aria-label={text}
              >
                <span className={styles["nav-text"]}>{text}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      <Button
        className={styles["nav__button"]}
        to="/#donate"
        theme="small"
        onClick={handleScroll("donate")}
        rel="nofollow"
      >
        Become a donor
      </Button>
    </nav>
  );
};

export { Nav };
