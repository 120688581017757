import {Image} from "../UI";
import configs from "../../config.json";

import cn from "classnames";
import styles from "./ContactUsSection.module.scss";

const ContactUsSection = () => {
  const { SUPPORT_EMAIL } = configs;

  return (
    <section className={styles["contact"]} id={'help-us'}>
      <div className={cn("wrapper", styles["contact__wrapper"])}>
        <Image
          className={styles["contact__image"]}
          src={"/images/home/contactUs.png"}
          width="430"
          height="410"
          alt="Contact us"
        />
        <div className={styles["contact__main-container"]}>
          <h2 className={cn(styles["contact__title"], 'main-title')}>
            Contact us
          </h2>
          <p className={styles["contact__description"]}>
            If you haven't found the answer to your question, feel free to contact us via email. Rest assured, we'll get back to you in no time!
          </p>

          <div className={styles['contact__email-wrap']}>
            <h4>Email us:</h4>
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;