import React from "react";
import cn from "classnames";
import {Helmet} from "react-helmet-async";

import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Page not found</title>
        <meta name="description" content="404 Page not found" />
      </Helmet>
      <div className={styles["page-404"]}>
        <div className={cn("wrapper", styles["page-404__wrapper"])}>
          <div className={styles["page-404__content"]}>
            <h2 className={cn("main-title", styles['page-404__title'])}>
              <span>404</span> Page not found
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
