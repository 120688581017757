const faqConfig = [
  {
    question: '1. How can I make a donation to CodeLearnFund?',
    answer: 'You can make a donation to CodeLearnFund directly through our website. We have made it easy and secure for you to donate online using your PayPal account. Simply click “Donate” on our main page, enter the amount you want to donate, and follow the prompts to complete the transaction.',
  },
  {
    question: '2. How will my donation be used?',
    answer: 'Your support plays a crucial role in advancing our mission of making programming education accessible to everyone. This includes developing and maintaining our educational platform and mobile app, creating a diverse curriculum, and providing support to our students. Every donation helps us bring high-quality, affordable programming education to more people.',
  },
  {
    question: '3. Who can benefit from CodeLearnFund?',
    answer: 'Our platform is meant for anyone interested in learning programming, regardless of their age or skill level. Whether the student is a complete beginner or an experienced coder looking to learn a new language or enhance their skills, we aim to create an educational environment that will meet every learner\'s needs.',
  },
  {
    question: '4. How can I track the impact of my donation on the fundraising goals?',
    answer: 'We believe in the importance of transparency and accountability. As a sponsor, you\'ll receive regular updates on our progress, including stories about the students and projects your donations are supporting. You can also subscribe to our newsletter for the latest updates.',
  },
  {
    question: '5. Is there a minimum or maximum donation amount?',
    answer: 'CodeLearnFund appreciates donations of all sizes. There is no strict minimum or maximum amount, allowing you to contribute according to your means and generosity.',
  }
];

export { faqConfig };
