const donateConfig = [
  {
    price: '$5 - $50',
    button: {
      text: 'Donate with PayPal',
      link: 'https://www.paypal.com/donate/?hosted_button_id=L6NVJGELEEKDA'
    },
    items: [
      {
        title: "Goal:",
        text: "Develop engaging and interactive learning courses for all skill levels"
      },
      {
        title: "Milestone:",
        text: "Regular updates about our progress"
      },
      {
        title: "BENEFIT:",
        text: "<b>1-month</b> premium subscription to one of our partner’s services"
      }
    ]
  },
  {
    price: '$50 - $99<sup>.99</sup>',
    button: {
      text: 'Donate with PayPal',
      link: 'https://www.paypal.com/donate/?hosted_button_id=L6NVJGELEEKDA'
    },
    items: [
      {
        title: "Goal:",
        text: "Enrich the program with practical exercises and in-depth tutorial"
      },
      {
        title: "Milestone:",
        text: "Exclusive previews of upcoming lessons"
      },
      {
        title: "BENEFIT:",
        text: "<b>3-month</b> premium subscription to one of our partner’s services"
      }
    ]
  },
  {
    price: '$100 - $149<sup>.99</sup>',
    button: {
      text: 'Donate with PayPal',
      link: 'https://www.paypal.com/donate/?hosted_button_id=L6NVJGELEEKDA'
    },
    items: [
      {
        title: "Goal:",
        text: "Create a mentorship program where learners can connect with industry professionals"
      },
      {
        title: "Milestone:",
        text: "Actively participate in shaping job preparation materials"
      },
      {
        title: "BENEFIT:",
        text: "<b>6-month</b> premium subscription to one of our partner’s services"
      }
    ]
  },
  {
    price: '$150 - $500',
    button: {
      text: 'Donate with PayPal',
      link: 'https://www.paypal.com/donate/?hosted_button_id=L6NVJGELEEKDA'
    },
    items: [
      {
        title: "Goal:",
        text: "Launch a user-friendly mobile app for coding education"
      },
      {
        title: "Milestone:",
        text: "An invitation to beta test our app before it goes public"
      },
      {
        title: "BENEFIT:",
        text: "<b>Special Exclusive</b> annual subscription to one of our partner’s services"
      }
    ]
  }
];

export { donateConfig };
