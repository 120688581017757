import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTopPage = () => {
  const { pathname } = useLocation();
  const hashId = new URL(window.location.href).hash.substring(1);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (hashId) {
      setTimeout(() => {
        const element = document.getElementById(`${hashId}`);
        const scrollPosition = element?.offsetTop - 30;

        if (element) {
          window.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        }
      }, 500)
    }
  }, [pathname, hashId]);
};

export const scrollToElement = (id, offsetCustom, callback) => (event) => {
  if (callback) {
    callback();
  }

  if (!id) return;
  const element = document.getElementById(`${id}`);
  const offset = offsetCustom ? offsetCustom : 30;
  const scrollPosition = element?.offsetTop - offset;
  if (element) {
    event.preventDefault();
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }
};

export const scrollToTop = (event) => {
  event.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
