import React from 'react';

export const Image = ({ src, alt, width, height, className }) => {
  const getMobileSrc = (src) => {
    const mobileSrc = src.replace(/\.(jpeg|jpg|png)/, '_mobile.$1'); // добавляем -mobile к имени файла
    const retinaMobileSrc = getRetinaImage(mobileSrc);
    return `${mobileSrc} 1x, ${retinaMobileSrc}`;
  };

  const getRetinaImage = (src) => {
    const retinaSrc = src.replace(/\.(jpeg|jpg|png)/, '@2x.$1'); // добавляем @2x к имени файла
    return `${retinaSrc} 2x`;
  };

  return (
    <picture className={className}>
      <source
        media="(max-width: 991px)"
        srcSet={getMobileSrc(src)}
      />
      <source
        media="(min-width: 992px)"
        srcSet={`${src} 1x, ${getRetinaImage(src)}`}
      />
      <img
        className="steps__image"
        width={width}
        height={height}
        src={src}
        alt={alt}
      />
    </picture>
  );
};
