import {IconArrowRight, IconEyezy, IconLasta, IconMspy} from "../UI";

import cn from "classnames";
import styles from "./TickerSection.module.scss";

export const TickerLogosSection = () => {
  return (
    <section className={cn(styles["ticker"], styles["ticker-logos"])}>
      <div className={cn("wrapper", styles["ticker__wrapper"])}>
        <div className={styles["ticker__items-wrapper"]}>
          <TickerItems>
            <span className={styles["ticker-logos__title"]}>
                Our Partners
              </span>
            <IconMspy />
            <IconEyezy />
            <IconLasta />
          </TickerItems>
        </div>
      </div>
    </section>
  );
};

export const TickerChooseSection= () => {
  return (
    <section className={cn(styles["ticker"], styles["ticker-choose"])}>
      <div className={cn("wrapper", styles["ticker__wrapper"])}>
        <div className={styles["ticker__items-wrapper"]}>
          <TickerItems>
            <span className={styles["ticker-choose__title"]}>
                Choose the amount
              </span>
            <IconArrowRight />
          </TickerItems>
        </div>
      </div>
    </section>
  );
};

const TickerItems = ({children}) => {
  return (
    <div className={styles["ticker__items-wrapper"]}>
      {Array.from({ length: 2 }).map((_, index) => (
        <div
          key={`ticker__items-${index}`}
          className={styles["ticker__items"]}
          aria-hidden={index === 1 ? "true" : undefined}
        >
          {Array.from({ length: 13 }).map((_, index) => (
            <div
              key={`logos-${index}`}
              className={styles["ticker__item"]}
            >
              {children}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};