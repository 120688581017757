import { Button, Image } from "../UI";

import cn from "classnames";
import styles from "./HeroSection.module.scss";
import {scrollToElement} from "../../utils/scrollToElement";

const HeroSection = () => {
  return (
    <section className={styles["hero"]} id={'top'}>
      <div className={cn("wrapper", styles["hero__wrapper"])}>
        <div className={styles["hero__additional-wrapper"]}>
          <div className={styles["hero__main-container"]}>
            <h1 className={styles["hero__title"]}>
              Invest in Affordable Coding Education
            </h1>
            <p className={styles["hero__description"]}>
              <b>CodeLearnFund</b> is a foundation dedicated to providing
              accessible programming learning for all aspiring programmers. In
              our inclusive space, coding skills become a right, not a
              privilege.
            </p>
            <Button
              className={styles["hero__button"]}
              href="#"
              rel="nofollow"
              onClick={(e) => scrollToElement("donate")(e)}
              theme={'animate'}
            >
              Become a donor
            </Button>
          </div>
          <Image
            className={styles["hero__image"]}
            src={"/images/home/hero.png"}
            width="589"
            height="426"
            alt="Hero"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
