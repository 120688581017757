import cn from "classnames";
import styles from "./AboutSection.module.scss";

const AboutSection = () => {
  return (
    <section className={styles["about"]} id={'top'}>
      <div className={cn("wrapper", styles["about__wrapper"])}>
        <div className={styles['about__additional-wrapper']}>
          <h2 className={cn(styles["about__title"], "main-title")}>
            About Our Coding Education Platform
          </h2>
          <p className={styles["about__description"]}>
            Our educational platform will serve as an all-in-one coding resource. It’ll offer a wide range of courses, tutorials, and interactive exercises designed to suit different learning preferences and skill levels. Here's a glimpse of what students can expect:
          </p>
          <div className={styles["about__items"]}>
            <div className={styles["about__item"]}>
              <span className={styles["about__item-number"]}>1</span>
              <h3 className={styles["about__item-title"]}>Diverse Programming Languages</h3>
              <p className={styles["about__item-text"]}>From the basics of Python, JavaScript, and HTML/CSS to more advanced languages like C++, Java, and Ruby, our platform aims to cover a broad spectrum of programming languages. This diversity allows students to choose the language that best suits their interests or career goals.</p>
            </div>
            <div className={styles["about__item"]}>
              <span className={styles["about__item-number"]}>2</span>
              <h3 className={styles["about__item-title"]}>Interactive Learning</h3>
              <p className={styles["about__item-text"]}>We believe in learning by doing, which is why our platform will feature interactive coding exercises and projects. This hands-on approach makes learning more engaging. It also helps students understand how the concepts they're learning apply to real-world problems.</p>
            </div>
            <div className={styles["about__item"]}>
              <span className={styles["about__item-number"]}>3</span>
              <h3 className={styles["about__item-title"]}>Personalized Learning Paths</h3>
              <p className={styles["about__item-text"]}>Every learner is unique. We acknowledge this by offering personalized learning paths. Students can set their own goals and pace, ensuring the learning process is tailored to their needs and aspirations.</p>
            </div>
            <div className={styles["about__item"]}>
              <span className={styles["about__item-number"]}>4</span>
              <h3 className={styles["about__item-title"]}>Community Support</h3>
              <p className={styles["about__item-text"]}>Learning doesn't happen in isolation. Our platform will include a community feature where students can connect with fellow learners, share knowledge, seek help, and collaborate on projects.</p>
            </div>
            <div className={styles["about__item"]}>
              <span className={styles["about__item-number"]}>5</span>
              <h3 className={styles["about__item-title"]}>Career Preparation</h3>
              <p className={styles["about__item-text"]}>In addition to technical skills, our platform will offer resources on soft skills, resume building, and interview preparation, helping students transition into the professional world of coding.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
