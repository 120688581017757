import { navConfig } from "./config";
import { Link } from "react-router-dom";
import configs from "../../config.json";

import cn from "classnames";
import styles from "./Footer.module.scss";

const Footer = () => {
  const { BRAND_NAME } = configs;

  return (
    <footer className={styles["footer"]}>
      <div className={cn("wrapper", styles["footer__wrapper"])}>
        <Link to={"/"} aria-label={`${BRAND_NAME} logo`}>
          <img
            className={styles["footer__logo"]}
            src={"/images/logo-white.svg"}
            alt={`${BRAND_NAME} logo`}
          />
        </Link>

        <div className={styles["footer__disclaimer"]}>
          <span className={styles["footer__disclaimer-title"]}>Disclaimer</span>
          <p className={styles["footer__disclaimer-text"]}>The information on this website is provided for information purposes in general only and does not claim to be or constitute legal or other professional advice and shall not be relied upon as such. We do not accept any responsibility for any loss or damage that may arise from accessing this website and from using this site and its content. The use of this website and the materials available on it is at your own risk. The information on this website is subject to change without notice.</p>
        </div>

        <nav className={styles["footer__nav"]}>
          <ul className={styles["footer__list"]}>
            {navConfig.map(({ href, text }) => {
              return (
                <li key={href} className={styles["footer__list-item"]}>
                  <Link
                    className={styles["footer__list-link"]}
                    to={href}
                    aria-label={text}
                  >
                    {text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <p className={styles["footer__copy"]}>
          © {new Date().getFullYear()} {BRAND_NAME}. <br /> All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
